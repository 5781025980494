import React from 'react';
import PermissionContext from './permissionContext';

const PermissionProvider = ({ fetchPermission, children }) => {
  const cache = {};
  const isAllowedTo = async (permission) => {
    if (Object.keys(cache).includes(permission?.to)) {
      return cache[permission?.to];
    }
    if (
      Object.keys(cache).includes(permission?.paymentRestriction?.featureName)
    ) {
      return cache[permission?.paymentRestriction?.featureName];
    }
    const isAllowed = await fetchPermission(permission);
    if (permission?.to) {
      cache[permission?.to] = isAllowed;
    }
    if (permission?.paymentRestriction?.isPaymentRestriction) {
      cache[permission?.paymentRestriction?.featureName] = isAllowed;
    }
    return isAllowed;
  };
  return (
    <PermissionContext.Provider value={{ isAllowedTo }}>
      {children}
    </PermissionContext.Provider>
  );
};

export default PermissionProvider;
