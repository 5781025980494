import palette from '../palette';

const MuiChip = {
  root: {
    fontSize: '12px',
    padding: '5px 5px',
  },
  colorSecondary: {
    backgroundColor: palette.error.main,
  },
};

export default MuiChip;
