import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import './App.css';
import theme from 'theme/mui';
import UnAuthenticated from 'components/UnAuthenticated';
import Authenticated from 'components/Authenticated';
import useUser from 'hooks/useUser/userContext';
import { NotificationProvider } from 'hooks/useNotification/useNotification';
import WunaLoader from './components/common/WunaLoader/WunaLoader';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useHistory, matchPath } from 'react-router-dom';
import { setReturnUrl } from 'utils/authCookie';
import Endpoints from 'constant/endpoints';
import axios from 'axios';
import PermissionProvider from 'components/PermissionProvider/permissionProvider';
import { fetchPermission } from 'services/permissionsService';
import ErrorCodesFromApi from 'constant/errorCodesFromApi';

function App() {
  const UNAUTHORIZED = 401;
  const { user, userLoading, logoutUnAuthorised } = useUser();
  const history = useHistory();
  if (
    !user &&
    (matchPath(history.location?.pathname ?? '', {
      path: Endpoints.QR_DETAILS_BY_ID,
    }) ||
      matchPath(history.location?.pathname ?? '', {
        path: Endpoints.CONFIRM_VERIFIER_ROLE,
      }))
  ) {
    setReturnUrl(history.location.pathname);
  }

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      const { status, data } = error.response;
      if (status === UNAUTHORIZED) {
        if (
          !matchPath(history.location?.pathname ?? '', {
            path: Endpoints.LOGIN,
          })
        ) {
          logoutUnAuthorised();
          history.push(Endpoints.LOGIN);
        }
      } else if (data?.code === ErrorCodesFromApi.ACCESS_DENIED) {
        history.push(Endpoints.ACCEESS_DENIED);
      }
      return Promise.reject(error);
    }
  );

  if (userLoading) {
    return <WunaLoader />;
  }
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <NotificationProvider>
          <PermissionProvider fetchPermission={fetchPermission(user)}>
            {user ? <Authenticated /> : <UnAuthenticated />}
          </PermissionProvider>
        </NotificationProvider>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
