import Roles from 'constant/role';
import LoginVerificationOptions from 'constant/loginVerifcationOptions';

export function isUserOnboarded(user) {
  return (
    (user.role !== Roles.END_USER && user.role !== Roles.VERIFIER) ||
    Boolean(user.onBoarded)
  );
}

export function isUserWithout2FA(user) {
  return (
    !isUserOnboarded(user) ||
    user.role === Roles.WUNA_ADMIN ||
    user.loginMethods?.length === 0
  );
}

export function isUserWithoutPin(user) {
  return (
    (user.role !== Roles.END_USER && user.role !== Roles.VERIFIER) ||
    !user.loginMethods?.includes(LoginVerificationOptions.PIN_CODE)
  );
}

export function getRoleUserFriendlyTitle(user) {
  return (
    <div style={{ textAlign: 'center', fontSize: '20px', color: 'white' }}>
      {user.role === Roles.VERIFIER ? (
        <span>WUNA+ Verifier</span>
      ) : user.role === Roles.ENTERPRISE_USER ? (
        <span>WUNA+ Privacy Officer</span>
      ) : user.role === Roles.THIRD_PARTY ? (
        <span>WUNA ID Verifier</span>
      ) : (
        <span></span>
      )}
    </div>
  );
}
