import UserStatus from 'constant/userStatus';
import FeatureStatus from 'constant/featureStatus';
import { getUserStatus, getUserPermissions } from '../services/usersClient';

export const fetchPermission = (user) => async (permission) => {
  let allowPermission = user?.status?.userStatus === UserStatus.ACTIVE;

  if (Boolean(permission?.to) && permission.to !== user?._id) {
    try {
      const response = await getUserStatus(permission.to);
      allowPermission = response?.data?.data?.userStatus === UserStatus.ACTIVE;
    } catch {
      allowPermission = false;
    }
  }

  if (
    Boolean(permission?.paymentRestriction?.isPaymentRestriction) &&
    allowPermission
  ) {
    try {
      const response = await getUserPermissions(user?.id, {
        featureName: permission?.paymentRestriction?.featureName,
      });
      allowPermission = response?.data?.data === FeatureStatus.ACTIVE;
    } catch {
      allowPermission = false;
    }
  }
  return allowPermission;
};
