import {
  TOKEN_KEY,
  getAuthorization,
  setAuthorization,
  removeAuthorization,
  setAuthorization2FA,
  removeAuthorization2FA,
  setReEnterPasswordToken,
} from 'utils/authCookie';
import { get, post } from 'utils/apiClient';
import { isUserWithout2FA } from 'utils/userUtils';

export async function saveIdentificationImage(data) {
  return post('auth/register_identification_image', {
    body: data,
  });
}

export async function login({ email, password }) {
  const response = await post('auth/login', { body: { email, password } });
  if (response.status < 400) {
    const { user } = response.data;
    isUserWithout2FA(user)
      ? setAuthorization(response.data.token.token)
      : setAuthorization2FA(response.data.token.token);
  }
  return response;
}

export async function login2faSendCode(body) {
  return await post('auth/send-code', { body: body, login2fa: true });
}

export async function login2fa(body) {
  const response = await post('auth/login2fa', { body: body, login2fa: true });
  if (response.status < 400) {
    setAuthorization(response.data.token.token);
    removeAuthorization2FA();
  }
  return response;
}

export async function loginVerifier(body) {
  const response = await post('auth/login-verifier', { body: body });
  if (response.status < 400) {
    removeAuthorization();
    setAuthorization(response.data.token.token);
  }
  return response;
}

export async function getSecurityQuestion(body) {
  return await get('auth/security-question', {
    body: body,
    login2fa: !Boolean(body.logedIn),
  });
}

export async function pauseUser(form) {
  return await post('auth/pause-user', {
    body: form,
  });
}

export async function checkPin(form) {
  return await post('auth/check-pin', {
    body: form,
  });
}

export async function signup({
  email,
  password,
  firstName,
  identificationImage,
  lastName,
  phone,
}) {
  const response = await post('auth/signup', {
    body: {
      email,
      password,
      firstName,
      lastName,
      phone,
    },
  });

  const { data } = response;
  const { user } = data;

  //add identification Image
  const formData = new FormData();
  formData.append('id', user._id);
  formData.append('identificationImage', identificationImage);
  formData.append('enterprise', 'Hold Access');
  formData.append('userSignup', true);
  await saveIdentificationImage(formData);

  return response;
}

export async function signupEnterprice(form) {
  const response = await post('auth/signup-enterprise', {
    body: form,
  });
  return response;
}

export async function signupThirdparty(form) {
  const response = await post('auth/signup-thirdparty', {
    body: form,
  });
  return response;
}

export async function logout() {
  removeAuthorization();
  removeAuthorization2FA();
  return { status: 'success' };
}

export async function getUser() {
  const token = getAuthorization();
  if (!token) {
    return null;
  }
  const response = await get('user');

  if (!response || response?.status >= 400) {
    removeAuthorization(TOKEN_KEY);
    removeAuthorization2FA();
    return null;
  }
  const {
    data: { user },
  } = response;
  return user;
}

export async function getVerifierUser() {
  const token = getAuthorization();
  if (!token) {
    return null;
  }
  const response = await get('user/verifier');
  if (response.status < 400) {
    const {
      data: { user },
    } = response;
    return user;
  }
  return null;
}

export async function forgotPassword(email) {
  return post(`auth/forgot-password`, { body: { email } });
}

export async function resetPassword({ password, token }) {
  return post(`auth/reset-password`, { body: { token, password } });
}

export async function reEnterPassword({ password }) {
  const response = await post(`auth/reenter-password`, { body: { password } });
  if (response.status < 400) setReEnterPasswordToken(response.data.token);
  return response;
}

export async function resetPinRequest(body) {
  return post('auth/reset-pin-request', { body: body });
}

export async function resetPin({ pin, token }) {
  return post(`auth/reset-pin`, { body: { token, pin } });
}

export async function disablePinRequest(body) {
  return post('auth/disable-pin-request', { body: body });
}

export async function disablePin({ token }) {
  return post(`auth/disable-pin`, { body: { token } });
}
