import omitBy from 'lodash/omitBy';
import { get, post, put, del } from 'utils/apiClient';
import { getReEnterPasswordToken } from '../utils/authCookie';

export async function getUser(id) {
  return get(`users/${id}`);
}
export async function updateUser(id, data) {
  return put(`users/${id}`, {
    body: data,
  });
}

export async function pauseUnpauseUser(id, data) {
  return put(`users/${id}/pause-unpause`, {
    body: data,
  });
}

export async function addUserToVerifierRole(id, data) {
  return put(`users/${id}/to-verifier-role`, {
    body: data,
  });
}

export async function sendRequestToUserToAddVerifierRole(id, data) {
  return put(`users/${id}/request-to-verifier-role`, {
    body: data,
  });
}

export async function resetVerifierPin(id) {
  return put(`users/${id}/reset-verifier-pin`);
}

export async function getAll(params = null) {
  const filteredParams = omitBy(
    params,
    (v) => v === undefined || v === null || v === ''
  );
  return get('users', { params: filteredParams });
}

export async function getUsersWithTags(params = null) {
  const filteredParams = omitBy(
    params,
    (v) => v === undefined || v === null || v === ''
  );
  return get('users/with-tags', { params: filteredParams });
}

export async function listAllUser(params) {
  return get('users/list-all', { params });
}

export async function associateUser(params) {
  return post('enterprise/associate-user', { body: params });
}

export async function addUser(params) {
  return post('users', { body: params });
}

export async function getUserDetails() {
  return get('users/details');
}

export async function getUserInfo() {
  return get('user');
}

export async function getUserFinancialDetails() {
  const token = getReEnterPasswordToken();
  return get('users/details/financial', { params: { token } });
}

export async function getUserDetailsById(id) {
  return get(`users/${id}/details`);
}

export async function getUserFinancialDetailsById(id) {
  const token = getReEnterPasswordToken();
  return get(`users/${id}/details/financial`, { params: { token } });
}

export async function updateUserDetails(data) {
  return put(
    Boolean(data.onBoarded) ? 'users/details-onboarding' : 'users/details',
    { body: data }
  );
}

export async function updateUserDetailsById(id, data) {
  return put(`users/${id}/details`, { body: data });
}

export async function getUserDetailsExcel(userId) {
  return get(`users/${userId}/download-details`, { responseType: 'blob' });
}
export async function getUserResume(userId) {
  return post(`resume/${userId}`, { responseType: 'blob' });
}

export async function getUserCredentials(userId) {
  return get(`users/${userId}/generate_credentials`);
}

export async function saveIdentificationImage(data) {
  return post('users/identification_image', {
    body: data,
  });
}

export async function deleteUser(userId) {
  return del(`users/${userId}`);
}

export async function unlinkUserEnterprise(userId, enterpriseId) {
  return post(`users/${userId}/unlink-user-enterprise`, {
    body: { enterpriseId },
  });
}

export async function restoreUser(userId) {
  return post(`users/${userId}/restore`);
}

export async function getGenderStats({ type }) {
  return get(`user/gender-stats`, { params: { type } });
}

export async function getUserCard(userId) {
  return get(`users/${userId}/current_card`);
}

export async function getVerifierRoleRequest(requestId) {
  return get(`users/${requestId}/verifier-role-request`);
}

export async function verifyRequest(requestId, body) {
  return put(`users/${requestId}/to-verifier-role`, { body });
}

export async function verifyUserByPhone(body) {
  return post(`users/verify-user-by-phone`, { body });
}

export async function getPhoneVerificationQuestions(userId) {
  return get(`users/${userId}/phone-verification-questions`);
}

export async function getAgeStats({ type }) {
  return get(`user/age-stats`, { params: { type } });
}

export async function getOriginalityStats({ type }) {
  return get(`user/originality-stats`, { params: { type } });
}

export async function getJobSectorStats({ type }) {
  return get(`user/job-sector-stats`, { params: { type } });
}

export async function getTilesStats() {
  return get(`user/tile-stats`);
}

export async function getTribalNationStats() {
  return get(`user/tribal-nation-stats`);
}

export async function getSkinGroups() {
  return get(`users/details/skinGroup`);
}

export async function getOldestAncestors() {
  return get(`users/details/oldestAncestor`);
}

export async function getEstateBelongings() {
  return get(`users/details/estateBelongings`);
}

export async function getTribalNations() {
  return get(`users/details/tribalNation`);
}

export async function getUserLogs(userId, params = null) {
  const filteredParams = omitBy(
    params,
    (v) => v === undefined || v === null || v === ''
  );
  return get(`users/${userId}/logs`, { params: filteredParams });
}

export async function getUserStatus(userId) {
  return get(`users/${userId}/status`);
}

export async function getUserPermissions(userId, data) {
  return post(`users/${userId}/payment-permissions`, { body: data });
}

export async function updateUserLoginMethods(data) {
  return post(`user/preffered-login-methods`, {
    body: data,
  });
}
