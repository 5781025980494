import palette from '../palette';

const MuiButton = {
  root: {
    fontSize: '0.675rem',
    borderRadius: '4px',
    padding: '11px 16px',
    fontWeight: '500',
    textTransform: 'capitalize',
  },
  contained: {
    boxShadow: 'none',
  },
  containedPrimary: {
    '&:hover': {
      backgroundColor: palette.primary.main,
    },
    '&:active': {
      backgroundColor: palette.primary.main,
    },
  },
  containedSecondary: {
    color: '#ffffff',
    backgroundColor: palette.error.main,
    '&:hover': {
      backgroundColor: palette.error.main,
    },
    '&:active': {
      backgroundColor: palette.error.main,
    },
  },
};

export default MuiButton;
