export const TOKEN_KEY = '__hold_acc_token__';
export const TOKEN_KEY_2FA = '__hold_acc_2FA_token__';
export const RE_ENTER_PASS_TOKEN_KEY = '__hold_acc_re_enter_token__';
export const RETURN_URL_KEY = '__hold_acc_return_url_token__';

export function getAuthorization() {
  return window.localStorage.getItem(TOKEN_KEY);
}

export function setAuthorization(token) {
  window.localStorage.setItem(TOKEN_KEY, token);
}

export function removeAuthorization() {
  return window.localStorage.removeItem(TOKEN_KEY);
}

export function getAuthorization2FA() {
  return window.localStorage.getItem(TOKEN_KEY_2FA);
}

export function setAuthorization2FA(token) {
  window.localStorage.setItem(TOKEN_KEY_2FA, token);
}

export function removeAuthorization2FA() {
  return window.localStorage.removeItem(TOKEN_KEY_2FA);
}

export function getReEnterPasswordToken() {
  return window.localStorage.getItem(RE_ENTER_PASS_TOKEN_KEY);
}

export function setReEnterPasswordToken(token) {
  window.localStorage.setItem(RE_ENTER_PASS_TOKEN_KEY, token);
}

export function removeReEnterPasswordToken() {
  return window.localStorage.removeItem(RE_ENTER_PASS_TOKEN_KEY);
}

export function setReturnUrl(returnUrl) {
  window.localStorage.setItem(RETURN_URL_KEY, returnUrl);
}

export function getReturnUrl() {
  return window.localStorage.getItem(RETURN_URL_KEY);
}

export function removeReturnUrl() {
  return window.localStorage.removeItem(RETURN_URL_KEY);
}
