import React, { useReducer, useEffect } from 'react';
import actionCreator from 'utils/actionCreator';
import * as authClient from 'services/authClient';
import { useSnackbar } from 'notistack';
import { serialize } from 'object-to-formdata';
import { getAuthorization } from 'utils/authCookie';
import ErrorCodesFromApi from 'constant/errorCodesFromApi';
import { isUserWithout2FA } from 'utils/userUtils';

const AuthContext = React.createContext();

const LOGIN = 'login';
const LOGIN_ERROR = 'login_error';
const LOGIN2FA_ERROR = 'login2fa_error';
const LOGIN_SUCCESS = 'login_success';
const LOGIN2FA_SUCCESS = 'login2fa_success';

const SIGNUP = 'signup';
const SIGNUP_ENTERPRICE = 'signupEnterprice';
const SIGNUP_ENTERPRICE_SUCCESS = 'signupEnterprice_success';
const SIGNUP_ENTERPRICE_ERROR = 'signupEnterprice_error';
const SIGNUP_THIRDPARTY = 'signupThirdparty';
const SIGNUP_THIRDPARTY_SUCCESS = 'signupThirdparty_success';
const SIGNUP_THIRDPARTY_ERROR = 'signupThirdparty_error';
const SIGNUP_ERROR = 'signup_error';
const SIGNUP_SUCCESS = 'signup_success';

const LOGOUT = 'logout';
const LOGOUT_SUCCESS = 'logout_success';

const PAUSE = 'pause';
const PAUSE_ERROR = 'pause_error';
const PAUSE_SUCCESS = 'pause_success';

const CHECK_PIN = 'check_pin';
const CHECK_PIN_ERROR = 'check_pin_error';
const CHECK_PIN_SUCCESS = 'check_pin_success';

const CHECK_IDENTITY_ERROR = 'check_identity_error';
const CHECK_IDENTITY_SUCCESS = 'check_identity_success';
const CHECK_IDENTITY = 'check_identity';

const USER_LOADING = 'USER_LOADING';

const login = actionCreator(LOGIN);
const loginError = actionCreator(LOGIN_ERROR);
const login2faError = actionCreator(LOGIN2FA_ERROR);
const loginSuccess = actionCreator(LOGIN_SUCCESS);
const login2faSuccess = actionCreator(LOGIN2FA_SUCCESS);

const checkPinLoading = actionCreator(CHECK_PIN);
const checkPinError = actionCreator(CHECK_PIN_ERROR);
const checkPinSuccess = actionCreator(CHECK_PIN_SUCCESS);

const pause = actionCreator(PAUSE);
const pauseError = actionCreator(PAUSE_ERROR);
const pauseSuccess = actionCreator(PAUSE_SUCCESS);

const signup = actionCreator(SIGNUP);
const signupEnterprice = actionCreator(SIGNUP_ENTERPRICE);
const signupEnterpriceSuccess = actionCreator(SIGNUP_ENTERPRICE_SUCCESS);
const signupEnterpriceError = actionCreator(SIGNUP_ENTERPRICE_ERROR);
const signupThirdparty = actionCreator(SIGNUP_THIRDPARTY);
const signupThirdpartySuccess = actionCreator(SIGNUP_THIRDPARTY_SUCCESS);
const signupThirdpartyError = actionCreator(SIGNUP_THIRDPARTY_ERROR);
const signupError = actionCreator(SIGNUP_ERROR);
const signupSuccess = actionCreator(SIGNUP_SUCCESS);

const userLoading = actionCreator(USER_LOADING);

const logout = actionCreator(LOGOUT);
const logoutSuccess = actionCreator(LOGOUT_SUCCESS);

const checkIdentityError = actionCreator(CHECK_IDENTITY_ERROR);
const checkIdentityLoading = actionCreator(CHECK_IDENTITY);
const checkIdentitySuccess = actionCreator(CHECK_IDENTITY_SUCCESS);

const LOGGED_IN_FIRST_STEP = 'The first step completed successfully.';
const LOGGED_IN = 'Logged In';
const LOGGED_OUT = 'Logged Out';
const SERVER_ERROR =
  'An unexpected Internal Server Error occurred. Please be assured that we are actively investigating the issue.';
const SIGNED_UP = 'Thank you for registering!';
const USER_PAUSED = 'Pause request confirmed. You are now on pause mode.';
const PIN_IS_CORRECT = 'Access granted.';
const RESET_PIN_SUCCESS_MESSAGE =
  "We've sent instructions to reset your PIN to your email.";
const DISABLE_PIN_SUCCESS_MESSAGE =
  "We've sent instructions to disable your PIN to your email.";
const initialState = {
  login: {
    error: null,
    loading: false,
    userInfo: null,
    strategy: null,
    initialEmail: null,
  },
  signup: {
    error: null,
    loading: false,
  },
  signupEnterprice: {
    error: null,
    loading: false,
  },
  signupThirdparty: {
    error: null,
    loading: false,
  },
  pause: {
    error: null,
    loading: false,
  },
  checkPin: {
    error: null,
    loading: false,
  },
  user: null,
  userLoading: true,
};

function authReducer(state, action) {
  const { type, payload } = action;
  switch (type) {
    case LOGIN:
      return {
        ...state,
        login: {
          error: null,
          loading: true,
          userInfo: null,
        },
        user: null,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        login: {
          error: null,
          loading: false,
          userInfo: payload,
        },
        user: null,
        userLoading: false,
      };
    case LOGIN2FA_SUCCESS:
      return {
        ...state,
        login: {
          error: null,
          loading: false,
        },
        user: payload,
        userLoading: false,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        login: {
          error: payload.error,
          loading: false,
          initialEmail: payload.initialEmail,
        },
        user: null,
        userLoading: false,
      };
    case LOGIN2FA_ERROR:
      return {
        ...state,
        login: {
          error: payload.error,
          loading: false,
          userInfo: payload.userInfo,
          strategy: payload.strategy,
        },
        user: null,
        userLoading: false,
      };
    case SIGNUP:
      return {
        ...state,
        signup: {
          error: null,
          loading: true,
        },
      };
    case SIGNUP_ENTERPRICE:
      return {
        ...state,
        signupEnterprice: {
          error: null,
          loading: true,
        },
      };
    case SIGNUP_ENTERPRICE_SUCCESS:
      return {
        ...state,
        signupEnterprice: {
          error: null,
          loading: false,
        },
      };
    case SIGNUP_ENTERPRICE_ERROR:
      return {
        ...state,
        signupEnterprice: {
          error: payload.error,
          loading: false,
        },
      };
    case SIGNUP_THIRDPARTY:
      return {
        ...state,
        signupThirdparty: {
          error: null,
          loading: true,
        },
      };
    case SIGNUP_THIRDPARTY_SUCCESS:
      return {
        ...state,
        signupThirdparty: {
          error: null,
          loading: false,
        },
      };
    case SIGNUP_THIRDPARTY_ERROR:
      return {
        ...state,
        signupThirdparty: {
          error: payload.error,
          loading: false,
        },
      };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        signup: {
          error: null,
          loading: false,
        },
      };
    case SIGNUP_ERROR:
      return {
        ...state,
        signup: {
          error: payload.error,
          loading: false,
        },
      };
    case LOGOUT:
      return {
        ...state,
        logout: {
          error: payload,
          loading: false,
        },
        user: null,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        logout: {
          error: null,
          loading: false,
        },
        user: null,
      };
    case USER_LOADING:
      return {
        ...state,
        userLoading: true,
      };
    case PAUSE:
      return {
        ...state,
        pause: {
          error: null,
          loading: true,
        },
      };
    case PAUSE_ERROR:
      return {
        ...state,
        pause: {
          error: payload.error,
          loading: false,
        },
      };
    case PAUSE_SUCCESS:
      return {
        ...state,
        pause: {
          error: null,
          loading: false,
        },
        user: payload,
      };

    case CHECK_PIN:
      return {
        ...state,
        checkPin: {
          error: null,
          loading: true,
        },
      };
    case CHECK_PIN_ERROR:
      return {
        ...state,
        checkPin: {
          error: payload.error,
          loading: false,
        },
      };
    case CHECK_PIN_SUCCESS:
      return {
        ...state,
        checkPin: {
          error: null,
          loading: false,
        },
      };
    case CHECK_IDENTITY_ERROR:
      return {
        ...state,
        login: {
          error: payload.error,
          loading: false,
          userInfo: payload.userInfo,
          strategy: payload.strategy,
        },
      };
    case CHECK_IDENTITY_SUCCESS:
      return {
        ...state,
        login: {
          error: null,
          loading: false,
        },
      };
    case CHECK_IDENTITY:
      return {
        ...state,
        login: {
          error: null,
          loading: true,
        },
      };
    default:
      throw new Error();
  }
}

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getUser() {
    dispatch(userLoading());
    const user = await authClient.getUser();
    if (Boolean(user?.user2fa)) {
      dispatch(loginSuccess(user));
    } else if (user) {
      dispatch(login2faSuccess(user));
    } else {
      dispatch(loginError({}));
    }
  }

  async function handleLogin(form) {
    try {
      dispatch(login());
      const response = await authClient.login(form);
      const { message, user } = response.data;
      if (response.status >= 400) {
        enqueueSnackbar(message, { variant: 'error' });
        dispatch(loginError({ error: message, initialEmail: form.email }));
      } else if (isUserWithout2FA(user)) {
        enqueueSnackbar(LOGGED_IN, { variant: 'success' });
        const user = await authClient.getUser();
        dispatch(login2faSuccess(user));
      } else {
        enqueueSnackbar(LOGGED_IN_FIRST_STEP, { variant: 'success' });
        dispatch(loginSuccess(user));
      }
    } catch (e) {
      enqueueSnackbar(SERVER_ERROR, { variant: 'error' });
      dispatch(loginError({ error: 'Invalid Email or Password' }));
    }
  }

  async function handleLogin2Fa(form) {
    try {
      dispatch(login());
      const response = await authClient.login2fa(form);
      const { message, code, dataResponse } = response.data;
      if (response.status >= 400) {
        enqueueSnackbar(message, { variant: 'error' });
        if (code === ErrorCodesFromApi.AUTHENTICATION_ERROR) {
          dispatch(
            login2faError({
              error: message,
              userInfo: dataResponse,
              strategy: form.strategy,
            })
          );
        } else {
          dispatch(loginError({ error: message }));
        }
      } else {
        enqueueSnackbar(LOGGED_IN, { variant: 'success' });
        const user = await authClient.getUser();
        dispatch(login2faSuccess(user));
      }
    } catch (e) {
      enqueueSnackbar(SERVER_ERROR, { variant: 'error' });
      dispatch(loginError({ error: 'Invalid code' }));
    }
  }

  async function login2faSendCode(form) {
    try {
      dispatch(login());
      const response = await authClient.login2faSendCode(form);
      const { message } = response.data;
      if (response.status >= 400) {
        enqueueSnackbar(message, { variant: 'error' });
        dispatch(
          login2faError({ error: message, userInfo: state.login.userInfo })
        );
      } else {
        enqueueSnackbar('Code has been sent', { variant: 'success' });
        dispatch(loginSuccess(state.login.userInfo));
      }
    } catch (e) {
      enqueueSnackbar(SERVER_ERROR, { variant: 'error' });
      dispatch(loginError({ error: 'Server error' }));
    }
  }

  async function handleSignup(form) {
    try {
      dispatch(signup());
      const response = await authClient.signup(form);
      const { message } = response.data;
      if (response.status >= 400) {
        enqueueSnackbar(message, { variant: 'error' });
        dispatch(signupError({ error: message }));
      } else {
        enqueueSnackbar(SIGNED_UP, { variant: 'success' });
        dispatch(signupSuccess());
        window.location = '/thankyou';
      }
    } catch (e) {
      enqueueSnackbar(SERVER_ERROR, { variant: 'error' });
      dispatch(signupError({ error: 'Invalid Email or Password' }));
    }
  }

  async function handleSignupEnterprice(form) {
    try {
      dispatch(signupEnterprice());

      const {
        name,
        email,
        abnNumber,
        acnNumber,
        logoImage,
        password,
        rtoNumber,
        firstName,
        lastName,
        phone,
        organizationPhone,
        jobTitle,
        addressLine1,
        suburb,
        state,
        postal,
      } = form;

      const signupForm = {
        contactPerson: {
          firstName: firstName,
          lastName: lastName,
          phone: phone,
          jobTitle: jobTitle,
        },
        address: {
          addressLine1: addressLine1,
          suburb: suburb,
          state: state,
          postal: postal,
        },
        phone: organizationPhone,
        name: name,
        email: email,
        abnNumber: abnNumber,
        rtoNumber: rtoNumber,
        acnNumber: acnNumber,
        password: password,
        logoImage: logoImage,
      };

      const response = await authClient.signupEnterprice(serialize(signupForm));
      const { message } = response.data;
      if (response.status >= 400) {
        enqueueSnackbar(message, { variant: 'error' });
        dispatch(signupEnterpriceError({ error: message }));
      } else {
        enqueueSnackbar(SIGNED_UP, { variant: 'success' });
        dispatch(signupEnterpriceSuccess());
        window.location = '/thankyou';
      }
    } catch (e) {
      enqueueSnackbar(SERVER_ERROR, { variant: 'error' });
      dispatch(signupEnterpriceError({ error: 'Invalid Email or Password' }));
    }
  }

  async function handleSignupThirdparty(form) {
    try {
      dispatch(signupThirdparty());

      const {
        name,
        email,
        abnNumber,
        acnNumber,
        logoImage,
        password,
        rtoNumber,
        firstName,
        lastName,
        phone,
        organizationPhone,
        jobTitle,
        addressLine1,
        suburb,
        state,
        postal,
      } = form;

      const signupForm = {
        contactPerson: {
          firstName: firstName,
          lastName: lastName,
          phone: phone,
          jobTitle: jobTitle,
        },
        address: {
          addressLine1: addressLine1,
          suburb: suburb,
          state: state,
          postal: postal,
        },
        phone: organizationPhone,
        name: name,
        email: email,
        abnNumber: abnNumber,
        rtoNumber: rtoNumber,
        acnNumber: acnNumber,
        password: password,
        logoImage: logoImage,
      };

      const response = await authClient.signupThirdparty(serialize(signupForm));
      const { message } = response.data;
      if (response.status >= 400) {
        enqueueSnackbar(message, { variant: 'error' });
        dispatch(signupThirdpartyError({ error: message }));
      } else {
        enqueueSnackbar(SIGNED_UP, { variant: 'success' });
        dispatch(signupThirdpartySuccess());
        window.location = '/thankyou';
      }
    } catch (e) {
      enqueueSnackbar(SERVER_ERROR, { variant: 'error' });
      dispatch(signupThirdpartyError({ error: 'Invalid Email or Password' }));
    }
  }

  async function handleLogout() {
    dispatch(logout());
    await authClient.logout();
    enqueueSnackbar(LOGGED_OUT, { variant: 'success' });
    dispatch(logoutSuccess());
  }

  async function logoutUnAuthorised() {
    const token = getAuthorization();
    if (token) {
      dispatch(logout());
      await authClient.logout();
    }
    dispatch(logoutSuccess());
  }

  async function pauseUser(form) {
    try {
      dispatch(pause());
      const response = await authClient.pauseUser(form);
      const { message, user } = response.data;
      if (response.status >= 400) {
        dispatch(pauseError({ error: message }));
        enqueueSnackbar(message, { variant: 'error' });
      } else {
        dispatch(pauseSuccess(user));
        enqueueSnackbar(USER_PAUSED, { variant: 'success' });
      }
      return response;
    } catch (e) {
      dispatch(pauseError({ error: SERVER_ERROR }));
      enqueueSnackbar(SERVER_ERROR, { variant: 'error' });
    }
  }

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async function checkPin(form) {
    try {
      dispatch(checkPinLoading());
      const response = await authClient.checkPin(form);
      await sleep(400);
      const { message, user } = response.data;
      if (response.status >= 400) {
        dispatch(checkPinError({ error: message }));
        enqueueSnackbar(message, { variant: 'error' });
      } else {
        dispatch(checkPinSuccess(user));
        enqueueSnackbar(PIN_IS_CORRECT, { variant: 'success' });
      }
      return response;
    } catch (e) {
      dispatch(checkPinError({ error: SERVER_ERROR }));
      enqueueSnackbar(SERVER_ERROR, { variant: 'error' });
    }
  }

  async function resetPinRequest(form) {
    try {
      dispatch(checkIdentityLoading());
      const response = await authClient.resetPinRequest(form);
      const { message, code, dataResponse } = response.data;
      if (response.status >= 400) {
        enqueueSnackbar(message, { variant: 'error' });
        if (code === ErrorCodesFromApi.AUTHENTICATION_ERROR) {
          dispatch(
            checkIdentityError({
              error: message,
              userInfo: dataResponse,
              strategy: form.strategy,
            })
          );
        } else {
          dispatch(loginError({ error: message }));
        }
      } else {
        enqueueSnackbar(RESET_PIN_SUCCESS_MESSAGE, { variant: 'success' });
        dispatch(checkIdentitySuccess());
      }
      return response;
    } catch (e) {
      enqueueSnackbar(SERVER_ERROR, { variant: 'error' });
      dispatch(loginError({ error: 'Invalid code' }));
    }
  }

  async function disablePinRequest(form) {
    try {
      dispatch(checkIdentityLoading());
      const response = await authClient.disablePinRequest(form);
      const { message, code, dataResponse } = response.data;
      if (response.status >= 400) {
        enqueueSnackbar(message, { variant: 'error' });
        if (code === ErrorCodesFromApi.AUTHENTICATION_ERROR) {
          dispatch(
            checkIdentityError({
              error: message,
              userInfo: dataResponse,
              strategy: form.strategy,
            })
          );
        } else {
          dispatch(loginError({ error: message }));
        }
      } else {
        enqueueSnackbar(DISABLE_PIN_SUCCESS_MESSAGE, { variant: 'success' });
        dispatch(checkIdentitySuccess());
      }
      return response;
    } catch (e) {
      enqueueSnackbar(SERVER_ERROR, { variant: 'error' });
      dispatch(loginError({ error: 'Invalid code' }));
    }
  }

  return (
    <AuthContext.Provider
      value={{
        state,
        login: handleLogin,
        login2fa: handleLogin2Fa,
        signup: handleSignup,
        signupEnterprice: handleSignupEnterprice,
        signupThirdparty: handleSignupThirdparty,
        logout: handleLogout,
        logoutUnAuthorised,
        login2faSendCode,
        getUser,
        pauseUser,
        checkPin,
        resetPinRequest,
        disablePinRequest,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}

export { AuthContext, AuthProvider, useAuth };
