const LoginVerificationOptions = Object.freeze({
  PIN_CODE: 'pin',
  CARD_NUMBER: 'card',
  SEQURITY_QUESTION: 'question',
  EMAIL: 'email',
  PHONE_CODE: 'sms',
  VERIFIER_PIN: 'verifierPin',
});

export default LoginVerificationOptions;
