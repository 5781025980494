const Endpoints = Object.freeze({
  QR_DETAILS_BY_ID: '/qr-details/:id',
  CONFIRM_VERIFIER_ROLE: '/confirm-verifier-role/:id',
  PHONE_VERIFICATION_VERIFY: '/phone-verification-verify/:id',
  LOGIN: '/login',
  SETTINGS: '/settings',
  ACCEESS_DENIED: '/access-denied',
});

export default Endpoints;
