const palette = {
  primary: {
    main: '#2E5BFF',
    light: '#2E5BFF33',
    hover: '#2E5BFF33',
    active: '#2E5BFF33',
    white: '#ffffff',
  },
  secondary: {
    main: '#D63649',
  },
  error: {
    main: '#D63649',
  },
  warning: {
    main: '#F7C137',
  },
  success: {
    main: '#33AC2E',
  },
  info: {
    main: '#43BFF6',
  },
  grey: {
    main: '#2E384D',
    light: '#8798AD',
  },
};

export default palette;
