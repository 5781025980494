import React, { Suspense } from 'react';
import { useHistory } from 'react-router-dom';
import WunaLoader from '../common/WunaLoader/WunaLoader';
import { getReturnUrl, removeReturnUrl } from 'utils/authCookie';
const Layout = React.lazy(() => import('components/Authenticated/Layout'));

export default function Authenticated() {
  const redirectUrl = getReturnUrl();
  const history = useHistory();
  if (redirectUrl) {
    history.push(redirectUrl);
    removeReturnUrl();
  }
  return (
    <Suspense fallback={WunaLoader}>
      <Layout />
    </Suspense>
  );
}
